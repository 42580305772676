import { IEnvironment } from './environment.interface';
import { CMS_CONFIG_LIVE, GTM_CONFIG, KAMELEOON_ENV, STORYBLOK_IFRAMES, UC_CONFIG } from './shared';

export const environment: IEnvironment = {
    production: true,
    preview:false,
    baseUrl: 'https://www.petprotect.de',
    cms: CMS_CONFIG_LIVE,
    iframes: STORYBLOK_IFRAMES,
    google_tag_manager: GTM_CONFIG,
    usercentrics: UC_CONFIG,
    kameleoon: KAMELEOON_ENV,
};
