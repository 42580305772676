import { ImageLoader, ImageLoaderConfig } from '@angular/common';
import { ɵImageConfig as ImageConfig } from '@angular/core';
import { ISbImageLoadParams } from '@seven1/model';
import { StoryblokImageConfig } from '../image.provider';

export interface SbImageLoaderConfig extends ImageLoaderConfig {
    loaderParams?: ISbImageLoadParams | {[key: string]: unknown};
}

export const storyblokImageLoaderFactory: (sbConfig: StoryblokImageConfig, optimizedImageConfig: ImageConfig) => ImageLoader = (sbConfig: StoryblokImageConfig, optimizedImageConfig: ImageConfig) => (config: SbImageLoaderConfig): string => {
    let url = config.src || '';
    const originalImageWidth: number = config.loaderParams?.width as number || 4000;
    const width = (config.width || 0);
    const imageWidth = width * sbConfig.dpiFactor;

    // don't scale svgs
    if (url.endsWith('.svg') || url.startsWith('/images/')) {
        return url;
    }

    // add /m if necessary
    if (
        imageWidth < originalImageWidth
        || config.isPlaceholder
        || config.loaderParams?.quality
    ) {
        url = `${url}/m`;

        // generate preview placeholder
        if (config.isPlaceholder) {
            // set placeholder width or smaller
            if (imageWidth < (optimizedImageConfig.placeholderResolution || 500)) {
                url = `${url}/${imageWidth}x0`;
            } else {
                url = `${url}/${optimizedImageConfig.placeholderResolution}x0`;
            }
        } else {
            // react on `srcset` widths
            if (imageWidth < originalImageWidth) url = `${url}/${imageWidth}x0`;
        }

        // filters
        const filters: string[] = [];
        if (config.loaderParams?.quality) {
            filters.push(`quality(${config.loaderParams.quality})`);
        }
        if (filters.length) {
            url = `${url}/filters:${filters.join(':')}`
        }
    }

    return url;
}
