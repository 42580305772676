import { ChangeDetectionStrategy, Component, computed, effect, inject, input, ViewEncapsulation } from '@angular/core';
import { IFaqBlock } from './faq.model';
import { FaqItemComponent } from './item';
import { FaqJsonLdSchema } from '@seven1/model';
import { JsonldService } from '@seven1/angular/jsonld';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { IRichTextBlock, ITextBlok } from '@seven1/angular-storyblok-components/text';

export function answerToText(answer: ITextBlok | IRichTextBlock): string | undefined {
    switch (answer.component) {
        case "richtext":
            return answer.text.text;
        case "text":
        default:
            return (answer as ITextBlok).text;
    }
}

@Component({
    selector: 'section[s1-faq]',
    imports: [FaqItemComponent],
    templateUrl: './faq.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 's1-faq',
        '[class]': 'classes()',
        '[attr.id]': 'block().componentId',
    }
})
export class FaqComponent {
    static nextId = 0;
    id = `s1-faq--${++FaqComponent.nextId}`;
    private jsonld = inject(JsonldService);
    private _tailwind = inject(TailwindService);

    block = input.required<IFaqBlock>();
    classes = computed(() => {
        const block = this.block();
        return [
            ...this._tailwind.getSpacingClasses(block),
        ];
    });

    constructor() {
        effect(() => {
            const blok = this.block();
            if (blok.jsonldSchema && blok.children) {
                const schema = new FaqJsonLdSchema();
                for (const item of blok.children) {
                    schema.addQuestion(item.question, item.answers?.map(a => answerToText(a)).join('\n') || '');
                }
                this.jsonld.createSchema(schema, this.id);
            } else {
                this.jsonld.removeSchema(this.id);
            }
        });
    }
}
