@let blok = block();

@if (blok.promoted) {
    <section class="app-tariff-option-card--promoted">
        {{ blok.promotedText }}
    </section>
}
@if (blok.title || blok.subtitle) {
    <section class="app-tariff-option-card--header">
        @if (blok.title) {
            <h4 class="app-tariff-option-card--title">
                {{ blok.title }}
            </h4>
        }
        @if (blok.subtitle) {
            <p class="app-tariff-option-card--subtitle">
                {{ blok.subtitle }}
            </p>
        }
        @if (blok.actions?.length) {
            @for (action of blok.actions; track action._uid) {
                <s1-button [block]="action" class="mt-3" />
            }
        }
    </section>
}
@if (blok.price?.length || blok.priceSubline?.length) {
    <section class="app-tariff-option-card--price-section">
        @if (blok.price) {
            <h5 class="app-tariff-option-card--price">
                {{ blok.price }}
            </h5>
        }
        @if (blok.priceSubline) {
            <p class="app-tariff-option-card--price-subline">
                {{ blok.priceSubline }}
            </p>
        }
    </section>
}
@if (blok.secondaryBox) {
    <p class="app-tariff-option-card--secondary-section">
        {{ blok.secondaryBox }}
    </p>
}
@if (blok.content?.length) {
    <section class="app-tariff-option-card--content">
        @for (content of blok.content; track content._uid) {
            <ol app-list [block]="content">
            </ol>
        }
    </section>
}

@if (blok.footer?.length) {
    @for (footer of blok.footer; track footer._uid) {
        <footer class="app-tariff-option-card--footer">
            @if (footer.text) {
                <h5>
                    {{ footer.text }}
                </h5>
            }
            @if (footer.secondaryText) {
                <p>
                    {{ footer.secondaryText }}
                </p>
            }
        </footer>
    }
}
