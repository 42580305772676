@let blok = block();

@switch (blok.link.linktype) {
    @case ('url') {
        <!-- desanitize slash or update sanitizer -->
        <a [ngClass]="classes()"
           class="s1-link"
           [href]="sanitizedLink() | desanitizeSlash"
           [target]="blok.link.target"
           [rel]="blok.link.target === '_blank' ? 'noopener noreferrer' : undefined">
            {{ blok.text }}
        </a>
    }
    @case ('story') {
        <a class="s1-link"
           [ngClass]="classes()"
           [routerLink]="blok.link.cached_url | sbRouterLink | cachedUrlResolver"
           routerLinkActive="active-link"
           [fragment]="blok.link.anchor ? blok.link.anchor : undefined"
           [target]="blok.link.target"
           (click)="(blok.link.anchor) && scrollTo(blok.link.anchor)"
           (keydown.space)="(blok.link.anchor) && scrollTo(blok.link.anchor)"
           (keydown.enter)="(blok.link.anchor) && scrollTo(blok.link.anchor)"
            >
            {{ blok.text }}
        </a>
    }
    @case ('asset') {
        <a class="s1-link"
           [ngClass]="classes()"
           [href]="sanitizedLink() | desanitizeSlash"
           [target]="blok.link.target">
            {{ blok.text }}
        </a>
    }
    @case ('email') {
        <a
            class="s1-link"
            [ngClass]="classes()"
            [href]="'mailto:' + sanitizedLink() | desanitizeSlash">
            {{ blok.text }}
        </a>
    }
}
