import { ChangeDetectionStrategy, Component, computed, effect, inject, input, signal, ViewEncapsulation } from '@angular/core';
import { ISbStories } from 'storyblok-js-client';
import { StoryblokService } from '@seven1/angular-storyblok/api';
import { IBlogCategoryBlok } from '@seven1/model';
import { IBlogCategoriesCardBlok } from './blog-categories-card.model';
import { RouterLink } from '@angular/router';
import { CachedUrlResolverPipe } from '@seven1/angular-storyblok/link';
import { RouterLinkPipe } from '@seven1/angular-storyblok-components/cta';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';

@Component({
    selector: 's1-blog-categories-card',
    imports: [
        RouterLink,
        CachedUrlResolverPipe,
        RouterLinkPipe
    ],
    templateUrl: './blog-categories-card.component.html',
    styleUrl: './blog-categories-card.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'blog-categories-card',
        '[class]': 'classes()',
    }
})
export class BlogCategoriesCardComponent {
    private _story = inject(StoryblokService);
    private _tailwindService = inject(TailwindService);
    categories = signal<ISbStories<IBlogCategoryBlok> | null>(null);
    block = input.required<IBlogCategoriesCardBlok>();

    classes = computed(() => {
        const blok = this.block()
        return [
            ...this._tailwindService.getDisplayClasses(blok),
            ...this._tailwindService.getSpacingClasses(blok),
        ];
    })

    constructor() {
        effect(async () => {
            const params = {
                content_type: 'BlogCategoryPage',
                sort_by: 'name:asc',
                excluding_fields: 'body,sidebar',
                version: this._story.config.version,
            };
            const res = await this._story.getStories<IBlogCategoryBlok>(params);
            this.categories.set(res);
        });
    }

}
