@let blok = block();

<p class="app-redirect--paragraph"><span class="font-bold">From: </span>{{ blok.from.url || blok.from.cached_url }}</p>
<p class="app-redirect--paragraph"><span class="font-bold">To: </span> {{ blok.to.url || blok.to.cached_url }}</p>
<div class="app-redirect--status-wrapper">
    <p><span class="font-bold">Status Code: </span>{{ blok.statusCode }}</p>
    <button
        [ngClass]="blok.active ? 'bg-success' : 'bg-error'"
        class="app-redirect--status-button"
    >
        {{ blok.active ? 'Active' : 'Inactive' }}
    </button>
</div>
