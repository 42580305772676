@let blok = block();
@let isOpen = open();

<button (click)="toggle()"
        class="s1-faq-item--toggle"
        [class.is-open]="isOpen">
    <h4 class="s1-faq-item--toggle-title"
        [attr.aria-describedby]="id">
        {{ blok.question }}
    </h4>
    <img src="/icons/arrow.svg"
         class="s1-faq-item--toggle-chevron"
         alt="chevron to close and open faq item"
         height="24"
         width="24"
         loading="lazy" />
</button>
@if (blok.answers?.length) {
    <div [id]="id"
         [attr.aria-hidden]="!isOpen"
         class="s1-faq-item--content"
         [class.hidden]="!isOpen">
        @for (answer of blok.answers; track answer._uid) {
            @switch (answer.component) {
                @case ("text") {
                    <p class="s1-faq-item--paragraph">
                        {{answer.text}}
                    </p>
                }
                @default {
                    <s1-cms-component [blok]="answer">
                    </s1-cms-component>
                }
            }
        }
    </div>
}
