@let blok = block();
<table class="s1-table--table">
    @if (blok.content?.thead) {
        <thead class="s1-table--thead"
               [ngClass]="tableHeaderClasses()">
        <tr class="s1-table--row">
            @for (cell of blok.content?.thead; track cell._uid) {
                <th class="s1-table--cell s1-table--header-cell">
                    {{ cell.value }}
                </th>
            }
        </tr>
        </thead>
    }
    @if (blok.content?.tbody) {
        <tbody class="s1-table--tbody">
            @for (row of blok.content?.tbody; track row._uid) {
                <tr class="s1-table--row">
                    @for (cell of row.body; track cell._uid) {
                        <td class="s1-table--cell"
                            [innerHTML]="cell.value">
                        </td>
                    }
                </tr>
            }
        </tbody>
    }
    @if (blok.content?.tfooter) {
        <tfoot class="s1-table--tfoot">
            @for (row of blok.content?.tfooter; track row._uid) {
                <tr class="s1-table--row">
                    @for (cell of row.body; track cell._uid) {
                        <td class="s1-table--cell"
                            [innerHtml]="cell.value">
                        </td>
                    }
                </tr>
            }
        </tfoot>
    }
</table>
