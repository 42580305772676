import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { ButtonComponent } from '@seven1/angular-storyblok-components/cta';
import { ISbStoryData } from 'storyblok-js-client';
import { IBlogCategoryBlok } from '@seven1/model';

@Component({
    selector: 's1-blog-categories-preview',
    imports: [ NgOptimizedImage, ButtonComponent],
    templateUrl: './categories-preview.component.html',
    styleUrl: './categories-preview.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 's1-categories-preview',
    },
})
export class BlogCategoriesPreviewComponent {
    block = input.required<ISbStoryData<IBlogCategoryBlok>>();
}
