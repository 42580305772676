.s1-categories-preview {
    @apply rounded-2xl bg-white overflow-hidden hover:drop-shadow-xl transition-all duration-500 ease-in-out flex flex-col;

    &--image-wrapper{
        @apply relative h-64;
    }
    &--image {
        @apply block object-cover;
    }

    &--content {
        @apply flex-1 flex flex-col px-6 py-4 justify-between ;
    }

    &--paragraph{
        @apply my-4;
    }

    h3 {
        @apply text-lg md:text-xl lg:text-2xl mb-4;
    }

}
