import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { BreakpointsService } from '@seven1/angular/media';
import { KameleoonService } from '@seven1/angular/kameleoon';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { ITariffTableBlock } from './tariff-table.model';
import { TariffTableColumnComponent } from './column';
import { TariffTableRowGroupComponent } from './row-group';
import { TariffTableFooterRowComponent } from './footer';

@Component({
    selector: 'app-tariff-table',
    imports: [TariffTableColumnComponent, TariffTableRowGroupComponent, TariffTableFooterRowComponent],
    templateUrl: './tariff-table.component.html',
    styleUrl: './tariff-table.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[style]': `{
            "--tariff-table-columns-count": block().columns?.length || 0
        }`,
        '[class]': `classes()`,
        '[class.!hidden]': '!visible()',
        '[class.app-limited-content-width]': `!!block().limitedWidth`,
        '[attr.id]': '!block().componentId ? null : block().componentId',
    }
})
export class TariffTableComponent {
    private _route = inject(ActivatedRoute);
    private _kameleoon = inject(KameleoonService);
    private _breakpoints = inject(BreakpointsService);
    private _queryParams = toSignal(this._route.queryParamMap);
    private _minLg = toSignal(this._breakpoints.minLg$);
    private _tailwind = inject(TailwindService);

    block = input.required<ITariffTableBlock>();

    classes = computed(() => {
        const block = this.block();
        return ['block',
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getDisplayClasses(block)];
    });

    visible = computed(() => {
        return this._kameleoon.visible(this._queryParams(), this.block());
    });

    columnsStyle = computed(() => {
        const cols = `repeat(${this.block().columns?.length || 0}, minmax(0, 1fr))`;
       return this._minLg()?.matches ? `minmax(0, 2fr) ${cols}` : cols;
    });

    titleColumnStartStyle = computed(() => {
        return this._minLg()?.matches ? `span 1` : `span ${this.block().columns?.length || 0}`;
    });
}
