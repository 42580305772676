@let current = page();
@let pageArr = pageArray();
@let totalCount = totalPages();

<button [disabled]="current <= 1"
        (click)="page.set(page() - 1)"
        class="s1-pagination--nav">
    <span class="absolute"><</span>
</button>

@for(pageIndex of pageArr; track pageIndex) {
    <button (click)="page.set(pageIndex)"
            [class.is-active]="pageIndex === current"
            class="s1-pagination--index">
        <span class="absolute">{{pageIndex}}</span>
    </button>
}

<button [disabled]="current >= totalCount"
        (click)="page.set(page() + 1)"
        class="s1-pagination--nav">
    <span class="absolute">></span>
</button>
