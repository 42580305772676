import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { CmsComponent } from '@seven1/angular-storyblok-cms';
import { IBlogInfoCardBlock } from './blog-info-card.model';

@Component({
    selector: 's1-blog-info-card',
    imports: [
        CmsComponent
    ],
    templateUrl: './blog-info-card.component.html',
    styleUrl: './blog-info-card.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'blog-info-card',
        '[class]': 'hostClasses()'
    }
})

export class BlogInfoCardComponent {
    private _tailwind = inject(TailwindService);
    block = input.required<IBlogInfoCardBlock>();

    hostClasses = computed(() => {
        return [
            ...this._tailwind.getDisplayClasses(this.block()),
            ...this._tailwind.getSpacingClasses(this.block())
        ];
    })

}
