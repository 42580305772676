s1-cms-component:first-of-type > app-redirect {
    @apply mt-32;
}
s1-cms-component:last-of-type > app-redirect {
    @apply mb-32;
}


.app-redirect {
    &--host {
        @apply max-w-content-width mx-5 2xl:mx-auto px-5 md:px-8 py-3 my-5 grid gap-x-5 grid-rows-3 sm:grid-cols-2 sm:grid-rows-2 items-center bg-white shadow-2xl rounded-xl;
    }
    &--paragraph {
        @apply col-start-1 col-end-2
    }
    &--secound-paragraph {
        @apply mb-32;
    }
    &--status-wrapper {
        @apply sm:col-start-2 sm:col-end-3 sm:row-start-1 sm:row-end-3 flex gap-5 justify-between sm:justify-end items-center;
    }
    &--status-button {
        @apply min-w-32 px-4 py-2 text-white font-bold rounded;
    }
}
