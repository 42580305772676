@let blok = block();

<h3 class="app-tariff-table--title">
    {{blok.title}}
</h3>

<div class="app-tariff-table--wrapper">
    <header class="app-tariff-table--header"
            [style.grid-template-columns]="columnsStyle()">
        <div class="app-tariff-table--header-row-text">
            {{ blok.headerRowText }}
        </div>
        @for (column of blok.columns; track column._uid) {
            <div app-tariff-table-column
                 [block]="column">
            </div>
        }
    </header>
    @for (row of blok.rows; track row._uid) {
        <section app-tariff-table-row-group
                 [block]="row"
                 [columns]="blok.columns"
                 [columnsStyle]="columnsStyle()"
                 [titleColumnStartStyle]="titleColumnStartStyle()">
        </section>
    }

    @for (footerRow of blok.footer; track footerRow._uid) {
        <footer app-tariff-table-footer-row
                [style.grid-template-columns]="columnsStyle()"
                [block]="footerRow"
                [columns]="blok.columns">
        </footer>
    }
</div>
