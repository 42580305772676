import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ISbStoryData } from 'storyblok-js-client';
import { RouterLinkPipe } from '@seven1/angular-storyblok-components/cta';
import { CachedUrlResolverPipe } from '@seven1/angular-storyblok/link';
import { ArticlePreviewBlok } from './article-preview.model';

@Component({
    selector: 's1-article-preview',
    imports: [NgOptimizedImage, RouterLink, RouterLinkPipe, CachedUrlResolverPipe],
    templateUrl: './article.preview.html',
    styleUrl: './article.preview.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 's1-article-preview',
    },
})
export class BlogArticlePreview {
    block = input.required<ISbStoryData<ArticlePreviewBlok>>();
}
