@let blok = block();

@if (blok.title?.length || blok.subtitle?.length) {
    <div class="my-12">
        @if (blok.title) {
            <h2 class="s1-faq--title app-limited-content-width break-words">
                {{ blok.title }}
            </h2>
        }
        @if (blok.subtitle) {
            <p class="s1-faq--subtitle app-limited-content-width break-words">
                {{ blok.subtitle }}
            </p>
        }
    </div>
}

@if (blok.children?.length) {
    <ul class="s1-faq--list app-limited-content-width">
        @for (item of blok.children; track item._uid) {
            <li s1-faq-item
                [block]="item">
            </li>
        }
    </ul>
}
