import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { ISeparationLineBlock } from './separation-line.model';

@Component({
    selector: 'hr[s1-separation-line]',
    imports: [],
    template: '',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'classes()',
    }
})
export class SeparationLineComponent {
    private _tailwind = inject(TailwindService);
    block = input.required<ISeparationLineBlock>();

    classes = computed(() => {
        const blok = this.block();
        return [
            ...this._tailwind.getDisplayClasses(blok),
            ...this._tailwind.getSpacingClasses(blok),
            ...this._tailwind.getSizeClasses(blok),
            this.getBorderColor(blok.color),
            this.getBorderWidth(blok.separatorHeight)
        ];
    });

    getBorderColor(color: string | undefined) {
        switch (color) {
            case 'secondary':
                return 'border-secondary';
            case 'accent':
                return 'border-accent';
            case 'accent-light':
                return 'border-accent-light';
            case 'accent-dark':
                return 'border-accent-dark';
            case 'grey':
                return 'border-grey';
            case 'grey-light':
                return 'border-grey-light';
            case 'grey-dark':
                return 'border-grey-dark';
            case 'white':
                return 'border-white';
            case 'transparent':
                return 'border-transparent';
            case 'primary':
            default:
                return 'border-primary';
        }
    }

    getBorderWidth(separatorHeight: '1' | '0' | '2' | '4' | '8' | undefined) {
        switch (separatorHeight) {
            case '0':
                return 'border-0';
            case '2':
                return 'border-2';
            case '4':
                return 'border-4';
            case '8':
                return 'border-8';
            case '1':
            default:
                return 'border';
        }
    }
}
