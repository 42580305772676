import { ChangeDetectionStrategy, Component, computed, effect, inject, input, signal, ViewEncapsulation } from '@angular/core';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { StoryblokService } from '@seven1/angular-storyblok/api';
import { ISbStories } from 'storyblok-js-client';
import { IBlogCategoryBlok } from '@seven1/model';
import { ICategoriesListBlok } from './categories-list.model';
import { BlogCategoriesPreviewComponent } from '../article/categories-preview';

@Component({
    selector: 's1-blog-categories-list',
    imports: [BlogCategoriesPreviewComponent],
    templateUrl: './categories-list.component.html',
    styleUrl: './categories-list.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class:"block",
        '[class]': 'classes()',
    }
})
export class BlogCategoriesListComponent {
    private _story = inject(StoryblokService);
    private _tailwindService = inject(TailwindService);
    categories = signal<ISbStories<IBlogCategoryBlok> | null>(null);
    block = input.required<ICategoriesListBlok>();

    classes = computed(() => {
        const blok = this.block()
        return [
            ...this._tailwindService.getDisplayClasses(blok),
            ...this._tailwindService.getSpacingClasses(blok),
        ];
    })

    constructor() {
        effect(async () => {
            const params = {
                content_type: 'BlogCategoryPage',
                sort_by: 'name:asc',
                excluding_fields: 'body,sidebar',
                version: this._story.config.version,
            };
            const res = await this._story.getStories<IBlogCategoryBlok>(params);
            this.categories.set(res);
        });
    }
}
