import { inject, Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BASE_TITLE } from './seo.provider';

@Injectable({providedIn: 'root'})
export class PageTitleStrategy extends TitleStrategy {
    private _baseTitle = inject(BASE_TITLE);
    constructor(private readonly title: Title) {
        super();
    }
    override updateTitle(routerState: RouterStateSnapshot) {
        const title = this.buildTitle(routerState);
        if (title !== undefined) {
            this.title.setTitle(`${this._baseTitle} | ${title}`);
        } else {
            this.title.setTitle(this._baseTitle);
        }
    }
}
