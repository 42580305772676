import { ChangeDetectionStrategy, Component, computed, effect, inject, input, signal, ViewEncapsulation } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { IBlogArticleBlok } from '@seven1/model';
import { ISbStoryData } from 'storyblok-js-client';
import { StoryblokService } from '@seven1/angular-storyblok/api';
import { IBlogArticlesCardBlok } from './blog-articles-card.model';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { CachedUrlResolverPipe } from '@seven1/angular-storyblok/link';
import { RouterLinkPipe } from '@seven1/angular-storyblok-components/cta';
import { RouterLink } from '@angular/router';

@Component({
    selector: 's1-blog-articles-card',
    imports: [NgOptimizedImage, CachedUrlResolverPipe, RouterLinkPipe, RouterLink],
    templateUrl: './blog-articles-card.component.html',
    styleUrl: './blog-articles-card.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'blog-articles-card',
        '[class]': 'classes()',
    }
})
export class BlogArticlesCardComponent {
    private _story = inject(StoryblokService);
    private _tailwindService = inject(TailwindService);
    articles = signal<ISbStoryData<IBlogArticleBlok>[]| null>(null);
    block = input.required<IBlogArticlesCardBlok>();

    classes = computed(() => {
        const blok = this.block()
        return [
            ...this._tailwindService.getDisplayClasses(blok),
            ...this._tailwindService.getSpacingClasses(blok),
        ];
    })

    constructor() {
        effect(async () => {
            const articlesList: string[] = this.block()?.articles ?? [];

            if (!articlesList.length) {
                this.articles.set([]);
                return;
            }

            const params = {
                by_uuids: articlesList.join(','),
                excluding_fields: 'blogBody,body,sidebar',
                version: this._story.config.version,
            };

            const res = await this._story.getStories<IBlogArticleBlok>(params);

            if (!res?.data?.stories) {
                this.articles.set([]);
                return;
            }

            const sortedArticles = articlesList
                .map((id: string) =>
                    res.data.stories.find((article) => article.uuid === id)
                )
                .filter((article): article is ISbStoryData<IBlogArticleBlok> => article !== undefined);

            this.articles.set(sortedArticles);
        });
    }

}
