.s1-article-preview {
    @apply rounded-2xl overflow-hidden hover:drop-shadow-xl transition-all duration-500 ease-in-out;

    &--link {
        @apply flex flex-col gap-2 hover:text-primary transition-colors duration-200 ease-in-out h-full;
    }

    &--image {
        @apply block;
    }

    &--content {
        @apply px-6 py-4;
    }

    h3 {
        @apply text-lg md:text-xl lg:text-2xl mb-4;
    }

    p {

    }
}
