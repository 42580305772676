 @let blok = block();
 @let blogArticles = articles();


 <section class="s1-articles-list--list">
     @for (article of blogArticles?.data?.stories?.slice(((page() || 1) - 1) * (blok.per_page || 5), (page() || 1) * (blok.per_page || 5)); track article.full_slug) {
         <s1-article-preview [block]="article" class="s1-articles-list--item" />
     }
 </section>
<s1-pagination class="block my-6"
               [(page)]="page"
               (pageChange)="pageChange($event)"
               [total]="blogArticles?.data?.stories?.length || 1"
               [per_page]="blok.per_page || 5" />
