@let blok = block();

@if (blok.title) {
  <p class="p-4 italic">
    {{blok.title}}
  </p>
}
<!-- main row -->
@if (blok.mainRow?.length) {
    @for (mainRow of blok.mainRow; track mainRow._uid) {
        <div app-tariff-table-row
             [block]="mainRow"
             [columns]="columns()"
             [style.grid-template-columns]="columnsStyle()"
             [columnsStyle]="columnsStyle()"
             [titleColumnStartStyle]="titleColumnStartStyle()"
             [children]="blok.rows">
        </div>
    }
}
