@let category = block();


@if (category.content.image?.filename?.length) {
    <div class="s1-categories-preview--image-wrapper">
        <img class="s1-categories-preview--image"
             [ngSrc]="category.content.image!.filename"
             [fill]="true"
             [alt]="category.content.image?.alt"
             [title]="category.content.image?.title"
        >
    </div>
}
<div class="s1-categories-preview--content">
    <div>
        <h3>
            {{ category.content.title }}
        </h3>
        <p class="s1-categories-preview--paragraph">
            {{ category.content.description }}
        </p>
    </div>
    <s1-button [block]="{
        link: {
           linktype: 'story',
           cached_url: category.full_slug
        },
        size: 'default',
        text: 'Zu den Beiträgen',
        color: 'accent-light',
        marginX: 'auto',
    }"/>
</div>

