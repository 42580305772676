import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import {
    InMemoryScrollingOptions,
    PreloadAllModules,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
    withRouterConfig
} from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration, withEventReplay, withHttpTransferCacheOptions, withIncrementalHydration } from '@angular/platform-browser';
import { provideStoryblok } from '@seven1/angular-storyblok/api';
import { environment } from '../environments/environment';
import { provideStoryblokImages } from '@seven1/angular-storyblok/image';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideGtm } from '@seven1/angular/gtm';
import { provideKameleoon } from '@seven1/angular/kameleoon';
import { provideUsercentrics } from '@seven1/angular/usercentrics';
import { provideSeo } from '@seven1/angular/seo';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideEnvironment } from '@seven1/angular-storyblok/environment';


const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
};


export const appConfig: ApplicationConfig = {
    providers: [
        provideEnvironment(environment),
        provideAnimationsAsync(),
        provideHttpClient(
            withFetch(),
        ),
        provideClientHydration(
            withEventReplay(),
            withHttpTransferCacheOptions({
                includePostRequests: true,
            }),
            withIncrementalHydration(),
        ),
        provideZoneChangeDetection({
            eventCoalescing: true,
            runCoalescing: true,
        }),
        provideRouter(
            appRoutes,
            withRouterConfig({}),
            withPreloading(PreloadAllModules), // todo
            withInMemoryScrolling(scrollConfig),
        ),
        provideGtm(environment.google_tag_manager),
        provideUsercentrics(environment.usercentrics),
        provideKameleoon(environment.kameleoon),
        provideStoryblok(environment.cms),
        provideStoryblokImages(),
        provideSeo('Petprotect'),
    ],
};
