@let blok = block();
<div [ngClass]="getOuterClasses()" class="relative overflow-hidden">
    <div [ngClass]="getMaxWidthClasses()">
        @for (child of blok.children; track child['_uid']) {
            <s1-cms-component [blok]="child"/>
        }
        @if (blok.overlay) {
            <div class="absolute inset-0 flex justify-center items-center flex-col overflow-hidden p-4"
                [ngClass]="getInnerClasses()">
                <ng-container *ngTemplateOutlet="OverlayContent"/>
            </div>
        } @else {
            <div class="absolute inset-0 flex justify-center items-center flex-col overflow-hidden p-4">
                <ng-container *ngTemplateOutlet="OverlayContent"/>
            </div>
        }
    </div>
</div>


<ng-template #OverlayContent>
    @if ((blok.children ?? []).length > 0) {
        <div>
            @for (child of blok.overlayChildren; track child['_uid']) {
                <s1-cms-component [blok]="child"/>
            }
        </div>
    }
</ng-template>
