.blog-info-card {
    @apply flex flex-col border-2 border-accent-dark bg-white;

    &--header-wrapper {
        @apply p-4 bg-accent-dark;
    }

    &--header-paragraph{
        @apply text-center font-bold text-3xl;
    }

    &--body-wrapper{
        @apply p-4;
    }
}
