import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { IVideoBlock } from './video.model';
import { DomSanitizer } from '@angular/platform-browser';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';

@Component({
    selector: 's1-video',
    standalone: true,
    templateUrl: './video.component.html',
    styleUrl: './video.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'classes()',
        class: 'block',
    },
})
export class VideoComponent {
    private _sanitizer = inject(DomSanitizer);
    private _tailwind = inject(TailwindService);
    block = input.required<IVideoBlock>();
    classes = computed<string[]>(() => {
        return [
            ...this._tailwind.getDisplayClasses(this.block()),
            ...this._tailwind.getSpacingClasses(this.block()),
            ...this._tailwind.getSizeClasses(this.block()),
        ];
    });

    sanitizedVideoUrl = computed(() => {
        const blok = this.block();
        return this._sanitizer.bypassSecurityTrustResourceUrl(blok.video?.filename || '');
    });
}
