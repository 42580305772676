.s1-tooltip {

    &__dnf {
        @apply p-4 rounded-full not-italic font-bold;
    }

    &__inner-div {
        @apply absolute bg-secondary text-white left-1/2 bottom-full mb-2 w-max max-w-44 -translate-x-1/2 translate-y-1 scale-95 rounded px-3 py-2 text-sm opacity-0 shadow-lg transition-all duration-300 ease-out group-hover:translate-y-0 group-hover:scale-100 group-hover:opacity-100 pointer-events-none;

    }

    &__triangle {
        @apply absolute left-1/2 top-full -translate-x-1/2 w-0 h-0 border-t-[6px] border-t-secondary border-l-[6px] border-l-transparent border-r-[6px] border-r-transparent;
    }
}
