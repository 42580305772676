import {
    ChangeDetectionStrategy,
    Component, computed,
    inject,
    input,
    ViewEncapsulation
} from '@angular/core';
import { IListBlock } from './list.model';
import { ListElementComponent } from '../listElement/listElement.component';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';

@Component({
    selector: 'ol[app-list]',
    imports: [ListElementComponent],
    templateUrl: './list.component.html',
    styleUrl: './list.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'classes()',
    },
})
export class ListComponent {
    private _tailwind = inject(TailwindService);

    block = input.required<IListBlock>();
    classes = computed<string[]>(() => {
        const block = this.block();
        return [
            ...this._tailwind.getDisplayClasses(block),
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getSizeClasses(block),
            ...this._tailwind.getTypographyClasses(block),
        ];
    });
}
