import { ChangeDetectionStrategy, Component, computed, inject, input, signal, ViewEncapsulation } from '@angular/core';

import { IIFrameBlock } from './iframe.model';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../../../environments/environment';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';

@Component({
    selector: 'iframe[app-iframe]',
    imports: [],
    templateUrl: './iframe.component.html',
    styleUrl: './iframe.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-iframe',
        '[class]': 'classes()',
        '[src]': 'sanitizedLink()',
        '(load)': 'onLoad()',
    },
})
export class IframeComponent {
    private _tailwind = inject(TailwindService);
    private _sanitizer = inject(DomSanitizer);
    loading = signal(true);

    block = input.required<IIFrameBlock>();
    classes = computed(() => {
        const block = this.block();
        return [...this._tailwind.getSpacingClasses(block)];
    });
    sanitizedLink = computed(() => {
        const blok = this.block();
        switch (blok.category) {
            case 'dog':
                return this._sanitizer.bypassSecurityTrustResourceUrl(environment.iframes['dog'][blok.option_dog]);
            case 'cat':
                return this._sanitizer.bypassSecurityTrustResourceUrl(environment.iframes['cat'][blok.option_cat]);
            case 'horse':
                return this._sanitizer.bypassSecurityTrustResourceUrl(environment.iframes['horse'][blok.option_horse]);
            case 'general':
                return this._sanitizer.bypassSecurityTrustResourceUrl(environment.iframes['general']);
        }
    });

    onLoad(): void {
        this.loading.set(false);
    }
}
