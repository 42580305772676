import { ChangeDetectionStrategy, Component, computed, effect, inject, input, signal, ViewEncapsulation } from '@angular/core';
import { IBlogArticlesBlok } from './articles-vew.model';
import { ArticlePreviewBlok, BlogArticlePreview } from '../preview';
import { StoryblokService } from '@seven1/angular-storyblok/api';
import { ISbStories, ISbStoriesParams } from 'storyblok-js-client';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';

@Component({
    selector: 's1-articles-view',
    imports: [BlogArticlePreview],
    templateUrl: './articles.view.html',
    styleUrl: './articles.view.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 's1-articles-view',
        '[class]': 'classes()'
    }
})
export class BlogArticlesView {
    private _storyblok = inject(StoryblokService);
    private _tailwind = inject(TailwindService);
    block = input<IBlogArticlesBlok>();

    classes = computed<string[]>(() => {
        const block = this.block();
        if (block) {
            return [
                ...this._tailwind.getSpacingClasses(block),
                ...this._tailwind.getDisplayClasses(block, 'grid'),
            ];
        }
        return [];
    })

    articles = signal<ISbStories<ArticlePreviewBlok> | null>(null);

    constructor() {
        effect(async () => {
            const block = this.block();
            if (block?.articles?.length) {
                const params: ISbStoriesParams = {
                    excluding_fields: 'body'
                };
                params.by_uuids_ordered = block.articles.join(',');
                this.articles.set(await this._storyblok.getStories<ArticlePreviewBlok>(params));
            }
        });
    }
}
