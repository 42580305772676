@let blok = block();
@let posts = articles();

@if (blok.title) {
    <h3 class="font-bold text-lg lg:text-3xl mb-4 break-words">{{ blok.title }}</h3>
}
@for (article of posts; track article.full_slug) {
    <a [routerLink]="article.full_slug  | sbRouterLink | cachedUrlResolver"
       class="flex flex-row mb-4 last:mb-0">
        @if (article.content.image?.filename?.length) {
            <img class="block !static !h-16 !w-16 object-cover object-center shrink-0 self-start mt-2"
                 [ngSrc]="article.content.image!.filename"
                 [fill]="true"
                 [alt]="article.content.image?.alt"
                 [title]="article.content.image?.title">
        }
        <p class="ml-4">
            {{ article.name }}
        </p>
    </a>
}
