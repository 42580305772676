@use "sass:map";

$media: (
    sm: 640px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
    2xl: 1440px,
);

@function media-get($breakpoint: sm) {
    @if (map.has-key($media, $breakpoint)) {
        @return map.get($media, $breakpoint);
    } @else {
        @debug "No media wth breakpoint #{$breakpoint} in $media";
    }
}

@mixin media($breakpoint: sm) {
    @media screen and (min-width: media-get($breakpoint)) {
        @content;
    }
}
