@let blok = block();
@let c = children();
@let t = tooltip();
@let cols = columns();
@let show = showChildren();
@let lg = minLg();

<button class="app-tariff-table--row-description cursor-pointer lg:cursor-default"
        (click)="toggle()"
        [style.grid-column-start]="titleColumnStartStyle()"
        [disabled]="lg?.matches">
  <div class="flex justify-between items-center">
    <span class="text-left">
        {{ blok.title }}
    </span>

    @if (t) {
      <s1-tooltip class="hidden lg:flex"
                   [block]="t" />
    }
    <img class="app-tariff-table--toggle block lg:hidden"
         [class.is-expanded]="show"
         ngSrc="/icons/arrow.svg"
         width="32"
         height="32"
         alt="arrow">
  </div>
</button>

@if (show) {
    @for (column of blok.columns; track column._uid; let i = $index) {
        <div app-tariff-table-row-column
             [block]="column"
             [column]="cols ? cols[i] : undefined">
        </div>
    }

    @for (childRow of c; track childRow._uid; let i = $index) {
        @let isLast = i === (c?.length || 0) - 1;
        <div class="app-tariff-table--row-description-child"
             [style.grid-column-start]="titleColumnStartStyle()"
             [class.is-last]="isLast">
            {{ childRow.title }}
        </div>
        @for (childCol of childRow.columns; track childCol._uid) {
            <div app-tariff-table-row-column
                 class="app-tariff-table--row-column-child"
                 [class.is-last]="isLast"
                 [block]="childCol">
            </div>
        }
    }
    @if (blok.info) {
        <div class="lg:hidden p-4 text-center"
             [style.grid-column-start]="titleColumnStartStyle()">
            {{blok.info}}
        </div>
    }
}

