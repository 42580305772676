@let blok = block();

<div class="blog-info-card--header-wrapper">
    @if (blok.headerChildren.length > 0) {
        @for (child of blok.headerChildren; track child._uid) {
            <s1-cms-component [blok]="child"/>
        }
    } @else {
        <p class="blog-info-card--header-paragraph">Header</p>
    }
</div>

@if (blok.bodyChildren.length > 0) {
    <div class="blog-info-card--body-wrapper">
        @for (child of blok.bodyChildren; track child._uid) {
            <s1-cms-component [blok]="child"/>
        }
    </div>
}
