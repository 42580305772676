import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { NgClass } from '@angular/common';
import { IRedirectsEntryBlok } from '@seven1/angular-storyblok-tools';

@Component({
    selector: 'app-redirect',
    imports: [
        NgClass
    ],
    templateUrl: './redirect.component.html',
    styleUrl: './redirect.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-redirect--host',
    }
})
export class RedirectComponent {
    block = input.required<IRedirectsEntryBlok>();

}
