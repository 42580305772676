.s1-rich-text {
    ul {
        @apply list-disc pl-5;
    }

    ol {
        @apply list-decimal pl-5;
    }

    li {
        @apply my-2;
    }
}
