import { ChangeDetectionStrategy, Component, computed, input, model, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 's1-pagination',
    imports: [],
    templateUrl: './pagination.component.html',
    styleUrl: './pagination.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 's1-pagination',
    }
})
export class PaginationComponent {
    page = model.required<number>();
    total = input.required<number>();
    per_page = input.required<number>();
    totalPages = computed<number>(() => {
        return Math.ceil((this.total() || 1) / (this.per_page() || 5));
    });
    pageArray = computed<number[]>(() => {
        const total = this.totalPages();
        const res = [];
        for (let i = 0; i < total; i++) {
            res.push(i + 1);
        }
        return res;
    });
}
