import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, SecurityContext, signal } from '@angular/core';
import { IScrollLinkBlok } from './scroll-link.model';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 's1-scroll-link',
    imports: [NgClass],
    templateUrl: './scroll-link.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'block',
        '[class]': 'getDisplayClasses()',
    },
})
export class ScrollLinkComponent {
    private _tailwind = inject(TailwindService);
    private _sanitizer = inject(DomSanitizer);
    private _router = inject(Router);

    block = input.required<IScrollLinkBlok>();

    sanitizedLink = signal<string | null>(null);

    getAllClasses = computed<string[]>(() => {
        const block = this.block();
        return [
            'cursor-pointer',
            ...this._tailwind.getTypographyClasses(block),
            ...this._tailwind.getSizeClasses(block),
            ...this._tailwind.getSpacingClasses(block),
        ];
    });

    getDisplayClasses = computed<string[]>(() => {
        return this._tailwind.getDisplayClasses(this.block());
    });

    constructor() {
        effect(() => {
            const link = this.block().link;
            this.sanitizedLink.set(this._sanitizer.sanitize(SecurityContext.URL, link));
        });
    }

    public scrollToTarget(): void {
        this._router.navigate([], { fragment: this.sanitizedLink() || undefined });
    }
}
