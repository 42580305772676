import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { IGridFourColumnBlock } from './grid-four-column.model';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { CmsComponent } from '@seven1/angular-storyblok-cms';
import { gridInputTransform } from '../grid-input.transform';
import { computedGridColumnClasses } from '../grid.utils';
import { NgClass } from '@angular/common';

@Component({
    selector: 'div[s1-grid-four-column]',
    imports: [CmsComponent, NgClass],
    templateUrl: './grid-four-column.component.html',
    styleUrl: './grid-four-column.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'grid grid-cols-12',
        '[class]': 'classes()',
    },
})
export class GridFourColumnComponent {
    private _tailwind = inject(TailwindService);

    // @ts-expect-error - transform works - fix later typing
    block = input.required<IGridFourColumnBlock>({ transform: gridInputTransform<IGridFourColumnBlock> });
    classes = computed(() => {
        const block = this.block();
        return [
            ...this._tailwind.getGapClasses(block),
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getDisplayClasses(block, 'grid'),
        ];
    });

    column1Classes = computed(() => {
        return this._tailwind.getColSpanClasses(computedGridColumnClasses(1, this.block()));
    });

    column2Classes = computed(() => {
        return this._tailwind.getColSpanClasses(computedGridColumnClasses(2, this.block()));
    });

    column3Classes = computed(() => {
        return this._tailwind.getColSpanClasses(computedGridColumnClasses(3, this.block()));
    });

    column4Classes = computed(() => {
        return this._tailwind.getColSpanClasses(computedGridColumnClasses(4, this.block()));
    });
}
