@let blok = block();
<div class="s1-grid-column--1 flex flex-col"
     [ngClass]="blok.contentAligned ? 'justify-center':''"
     [class]="column1Classes().join(' ')">
    @for (child of blok.column_one || blok.children1; track child._uid) {
        <s1-cms-component [blok]="child"/>
    }
</div>
<div class="s1-grid-column--2 flex flex-col"
     [class]="column2Classes().join(' ')"
     [ngClass]="blok.contentAligned ? 'justify-center':''">
    @for (child of blok.column_two || blok.children2; track child._uid) {
        <s1-cms-component [blok]="child"/>
    }
</div>

