@let number_of_ratings = +data().average.number_of_ratings;
@let rounded_average = +data().average.rounded_average;
@let seal = rounded_average < 4 || number_of_ratings < 50 ? "none" :
    rounded_average < 4.3 ? "bronze" :
        rounded_average < 4.7 ? "silver" : "gold";

<a href="https://www.ekomi.de/bewertungen-petprotect.html" target="_blank" rel="noopener noreferrer"
   class="ekomi__link">
    <img [ngSrc]="'/images/de_seal_' + seal + '.png'" width="190" height="190" [alt]="'eKomi ' + seal + ' badge' " class="ekomi__seal">
    <div>
        <h2 class="ekomi__heading">PETPROTECT</h2>
        <div class="ekomi__rating-stars">
            <div class="relative min-w-36">
                <img ngSrc="/images/star-grey-170x29.png" width="170" height="29" alt="Bottom Image" class="ekomi__rating-stars--grey">
                <div class="ekomi__rating-stars-wrapper--gold">
                    <img ngSrc="/images/star-yellow-170x29.png" width="170" height="29" alt="Top Image" class="ekomi__rating-stars--gold"
                         [style.width]="(rounded_average / 5 * 100) + '%'">
                </div>
            </div>
            <span class="ekomi__rating">{{ rounded_average }}/5</span>
        </div>
        <p class="ekomi__subheading">{{ number_of_ratings }} Kundenrezensionen</p>
    </div>
</a>
