@let post = block();

<a [routerLink]="post.full_slug | sbRouterLink | cachedUrlResolver"
   class="s1-article-preview--link">
    @if (post.content.image?.filename?.length) {
        <div class="relative h-24">
            <img class="s1-article-preview--image h-32 object-cover"
                 [ngSrc]="post.content.image!.filename"
                 fill
                 [alt]="post.content.image?.alt"
                 [title]="post.content.image?.title"
            >
        </div>
    }
    <div class="s1-article-preview--content">
        <h3>
            {{post.content.title}}
        </h3>
        <p>
            {{post.content.description}}
        </p>
    </div>
</a>
