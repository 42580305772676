import {
    ChangeDetectionStrategy,
    Component,
    signal,
    ViewEncapsulation,
    input,
    inject,
    computed,
} from '@angular/core';
import { IEkomiBlock } from './ekomi.model';
import { PlatformService } from '@seven1/angular/ssr';
import { HttpClient } from '@angular/common/http';
import * as serverEkomiData from '../../../../../../../public/data/ekomiData.json';
import { NgOptimizedImage } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';


@Component({
    selector: 'section[app-ekomi]',
    templateUrl: './ekomi.component.html',
    styleUrl: './ekomi.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgOptimizedImage
    ],
    host: {
        class: 'flex justify-center items-center',
        '[class]': 'classes()',
        '[attr.id]':'block().componentId'
    }
})
export class EkomiComponent {
    private _platformService = inject(PlatformService);
    private _tailwind = inject(TailwindService);
    private _http = inject(HttpClient)

    block = input.required<IEkomiBlock>();
    data = this._platformService.isBrowser
        ? toSignal(this._http.get<ekomiDataType>('/data/ekomiData.json'), {
            initialValue: serverEkomiData as ekomiDataType,
        })
        : signal<ekomiDataType>(serverEkomiData);


    classes = computed(() => {
        const block = this.block();
        return [
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getDisplayClasses(block),
        ];
    });

}


export type ekomiDataType = {
    average: {
        number_of_ratings: string
        exact_average: string
        rounded_average: string
        seal: string
    }
    snapshot: {
        info: {
            fb_count: string
            fb_avg: string
            fb_avg_detail: string | null
            active: boolean
            account_name: string
            ekomi_certificate: string
            ekomi_certificate_id: string
            ekomi_certificate_seo: string
        }
        feedbacks: Array<{
            transaction_id: string
            rating: string
            message: string
            comment: string
            delivered: string
        }>
        done: number
        done_at: number
    }
    last_updated_at: string
}
