import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { IBreadcrumbsBlock } from './breadcrumbs.model';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { RouterLinkPipe } from '@seven1/angular-storyblok-components/cta';

@Component({
    selector: 'app-breadcrumbs',
    imports: [RouterLink, RouterLinkPipe],
    templateUrl: './breadcrumbs.component.html',
    styleUrl: './breadcrumbs.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit {
    block = input.required<IBreadcrumbsBlock>();
    breadcrumbs = signal<{ label: string; path: string }[]>([]);
    private _router = inject(Router);
    private _tailwind = inject(TailwindService);

    ngOnInit() {
        this.breadcrumbs.set(this.updateBreadcrumbs());
    }

    classes = computed(() => {
        const block = this.block();
        return [...this._tailwind.getSpacingClasses(block), ...this._tailwind.getDisplayClasses(block)];
    });

    private updateBreadcrumbs() {
        const path = this._router.url.split('?')[0];
        const pathSegments = path.split('/').filter((segment) => segment);
        return [
            { label: 'Petprotect.de', path: '/' },
            ...pathSegments
                .map((segment, index) => {
                    const pathSegmentSliced = pathSegments.slice(0, index + 1).join('/');
                    const partToExclude = this.block()?.partToExclude ? this.block().partToExclude?.toLowerCase() : '';
                    if (pathSegmentSliced.endsWith('/' + partToExclude)) {
                        return undefined;
                    }
                    return {
                        label: this.formatLabel(segment),
                        path: '/' + pathSegments.slice(0, index + 1).join('/'),
                    };
                })
                .filter((item) => item !== undefined),
        ];
    }

    private formatLabel(segment: string): string {
        if (segment.startsWith('/')) {
            segment = segment.slice(1);
        }
        return segment.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    }
}
