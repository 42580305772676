import { PageWrapper } from '../app/cms/components/wrapper';
import {
    AdvantagesCardComponent,
    DecoratedHeadlineComponent,
    DecoratedSectionComponent,
    IconComponent,
    IframeComponent,
    LinkCardComponent,
    ListComponent,
    ListElementComponent,
    TariffCardComponent,
    TariffOptionCardComponent,
    TariffOptionCardsComponent,
    TariffTableColumnComponent,
    TariffTableComponent,
    TariffTableFooterColumnComponent,
    TariffTableFooterRowComponent,
    TariffTableRowComponent,
    TariffTableRowGroupComponent,
    EkomiComponent,
    RedirectComponent,
    BreadcrumbsComponent,
} from '../app/cms/components/nested';
import { CmsComponents } from '@seven1/angular-storyblok/api';
import { ImageComponent } from '@seven1/angular-storyblok-components/image';
import {
    BadgeComponent,
    ButtonComponent,
    LinkComponent,
    PhoneNumberComponent,
    ScrollLinkComponent
} from '@seven1/angular-storyblok-components/cta';
import { FooterComponent, LayoutWrapper } from '@seven1/angular-storyblok-layout';
import { HeadlineComponent, ParagraphComponent, RichTextComponent } from '@seven1/angular-storyblok-components/text';
import { VideoComponent } from '@seven1/angular-storyblok-components/video';
import { SectionComponent } from '@seven1/angular-storyblok-components/section';
import {
    GridFiveColumnComponent,
    GridFourColumnComponent,
    GridOneColumnComponent,
    GridThreeColumnComponent,
    GridTwoColumnComponent
} from '@seven1/angular-storyblok-components/grid';
import { OneDirectionComponent } from '@seven1/angular-storyblok-components/flex';
import { GroupComponent } from '@seven1/angular-storyblok-components/group';
import { FaqComponent, FaqItemComponent } from '@seven1/angular-storyblok-components/faq';
import { TooltipComponent } from '@seven1/angular-storyblok-components/tooltip';
import { FooterRecruitComponent } from '@seven1/angular-storyblok-components/footer-recruit';
import {
    BlogArticlesCardComponent,
    BlogArticlesList,
    BlogArticlesView,
    BlogCategoriesCardComponent,
    BlogCategoriesListComponent,
    BlogGeneralUseCardComponent, BlogInfoCardComponent
} from '@seven1/angular-storyblok-components/blog';
import { QuoteboxComponent } from '@seven1/angular-storyblok-components/quotebox';
import { ImportantInfoComponent } from '@seven1/angular-storyblok-components/important-info';
import { TableComponent } from '@seven1/angular-storyblok-components/table';
import { SeparationLineComponent } from '@seven1/angular-storyblok-components/separation-line';
import { CoverComponent } from '@seven1/angular-storyblok-components/cover';


export const STORYBLOK_COMPONENTS: CmsComponents = {
    // wrapper
    footer: FooterComponent,
    // media
    image: ImageComponent,
    // cta
    link: LinkComponent,
    button: ButtonComponent,
    phoneNumber: PhoneNumberComponent,
    badge: BadgeComponent,
    // wrapper
    page: PageWrapper,
    redirects_page: PageWrapper,
    footerRecruit: FooterRecruitComponent,
    // cards
    advantagesCard: AdvantagesCardComponent,
    linkCard: LinkCardComponent,
    tariffCard: TariffCardComponent,
    importantInfo: ImportantInfoComponent,
    quotebox: QuoteboxComponent,
    magazinInfoCard: BlogInfoCardComponent,
    cover: CoverComponent,
    // nested
    //
    layout:LayoutWrapper,
    section: SectionComponent,
    decorated_section: DecoratedSectionComponent,
    grid_one_column: GridOneColumnComponent,
    grid_two_column: GridTwoColumnComponent,
    grid_three_column: GridThreeColumnComponent,
    grid_four_column: GridFourColumnComponent,
    grid_five_column: GridFiveColumnComponent,
    one_direction_container: OneDirectionComponent,
    group: GroupComponent,
    // text
    heading: HeadlineComponent,
    decoratedHeading: DecoratedHeadlineComponent,
    paragraph: ParagraphComponent,
    richtext: RichTextComponent,
    list: ListComponent,
    listElement: ListElementComponent,
    separationLine: SeparationLineComponent,
    // media
    video: VideoComponent,
    icon: IconComponent,
    // advanced
    iframe: IframeComponent,
    faq: FaqComponent,
    faq_item: FaqItemComponent,
    tooltip: TooltipComponent,
    ekomi: EkomiComponent,
    redirect: RedirectComponent,
    breadcrumbs: BreadcrumbsComponent,
    scrollLink: ScrollLinkComponent,
    // table
    table: TableComponent,
    // tariff
    // card
    tariff_option_cards: TariffOptionCardsComponent,
    tariff_option_card: TariffOptionCardComponent,
    // table
    tariff_table: TariffTableComponent,
    tariff_table_column: TariffTableColumnComponent,
    tariff_table_row_group: TariffTableRowGroupComponent,
    tariff_table_row: TariffTableRowComponent,
    tariff_table_footer_row: TariffTableFooterRowComponent,
    tariff_table_footer_column: TariffTableFooterColumnComponent,

    // Blog
    BlogArticleList: BlogArticlesList,
    BlogArticles: BlogArticlesView,
    BlogCategoriesCard: BlogCategoriesCardComponent,
    BlogGeneralUseCard: BlogGeneralUseCardComponent,
    BlogArticlesCard: BlogArticlesCardComponent,
    BlogCategoriesList: BlogCategoriesListComponent,
}
