import { ChangeDetectionStrategy, Component, input, signal, ViewEncapsulation } from '@angular/core';
import { IFaqItemBlock } from './faq-item.model';
import { CmsComponent } from '@seven1/angular-storyblok-cms';

@Component({
    selector: 'li[s1-faq-item]',
    imports: [CmsComponent],
    templateUrl: './faq-item.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 's1-faq-item',
    },
})
export class FaqItemComponent {
    static nextId = 0;
    id = `s1-faq-item-${++FaqItemComponent.nextId}`;
    block = input.required<IFaqItemBlock>();
    open = signal(false);

    toggle() {
        this.open.set(!this.open());
    }
}
