import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { ButtonComponent } from '@seven1/angular-storyblok-components/cta';
import { ListComponent } from '../../../../text';
import { ITariffOptionCardBlock } from './tariff-option-card.model';

@Component({
    selector: 'app-tariff-option-card',
    imports: [ButtonComponent, ListComponent],
    templateUrl: './tariff-option-card.component.html',
    styleUrl: './tariff-option-card.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-tariff-option-card',
    },
})
export class TariffOptionCardComponent {
    block = input.required<ITariffOptionCardBlock>();
}
