// too big buttons for tariff for a range of screen sizes
@media (min-width: 1024px) and (max-width: 1280px) {
    div.app-tariff-table--footer-column s1-button a {
        @apply text-xs px-4 py-4;
    }
}

@media (min-width: 1281px) {
    div.app-tariff-table--footer-column s1-button a {
        @apply text-base px-3;
    }
}
