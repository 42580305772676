import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

export interface IBaseEnvironment {
    production: boolean,
    preview: boolean,
    baseUrl: string,
}

export const ENVIRONMENT = new InjectionToken<IBaseEnvironment>('Environment', {
    providedIn: 'root',
    factory: () => ({
        production: true,
        preview: false,
        baseUrl: 'http://www.test.de',
    }),
});

export function provideEnvironment(env: IBaseEnvironment): EnvironmentProviders {
    return makeEnvironmentProviders([
        {provide: ENVIRONMENT, useValue: env}
    ]);
}
