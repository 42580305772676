import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { FooterRecruitBlok } from './footer-recruit.model';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { CmsComponent } from '@seven1/angular-storyblok-cms';

@Component({
    selector: 's1-footer-recruit',
    imports: [CmsComponent],
    templateUrl: './footer-recruit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 's1-footer-recruit',
        '[class]': 'getDisplayClasses()',
    },
})
export class FooterRecruitComponent {
    private _tailwind = inject(TailwindService);
    block = input.required<FooterRecruitBlok>();

    getDisplayClasses = computed<string[]>(() => {
        return this._tailwind.getDisplayClasses(this.block());
    });
}
