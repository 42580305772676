import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { ITableBlok } from './table.model';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { NgClass } from '@angular/common';

@Component({
    selector: 's1-table',
    imports: [NgClass],
    templateUrl: './table.component.html',
    styleUrl: './table.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 's1-table',
        '[class]': 'classes()',
        '[class.app-limited-content-width]': 'block().limitedWidth',
    },
})
export class TableComponent {
    private _tailwind = inject(TailwindService);
    block = input.required<ITableBlok>();

    classes = computed<string[]>(() => {
        const blok = this.block();
        const res = [
            ...this._tailwind.getDisplayClasses(blok),
            ...this._tailwind.getSpacingClasses(blok),
            ...this._tailwind.getTypographyClasses(blok),
            `s1-table--appearance-${blok.appearance || 'standard'}`
        ];
        if (blok.bordered) {
            res.push(`s1-table--bordered`)
        }
        return res;
    });

    tableHeaderClasses = computed<string[]>(() => {
        const blok = this.block();
        return [
            ...this._tailwind.getTypographyClasses({
                textAlign: blok.headerTextAlign,
                textAlignTablet: blok.headerTextAlignTablet,
                textAlignDesktop: blok.headerTextAlignDesktop,
                color: blok.headerColor,
                fontSize: blok.headerFontSize,
                fontSizeTablet: blok.headerFontSizeTablet,
                fontSizeDesktop: blok.headerFontSizeDesktop,
            }),
        ];
    });
}
