import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ICoverBlok } from './cover.model';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { CmsComponent } from '@seven1/angular-storyblok-cms';

@Component({
    selector: 's1-cover',
    imports: [NgClass, CmsComponent, NgTemplateOutlet],
    templateUrl: './cover.component.html',
    styleUrl: './cover.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'getDisplayClasses()',
    },
})
export class CoverComponent {
    private _tailwind = inject(TailwindService);
    block = input.required<ICoverBlok>();

    getMaxWidthClasses = computed<string[]>(() => {
        return this._tailwind.getSizeClasses(this.block());
    });
    getOuterClasses = computed<string[]>(() => {
        const blok = this.block();
        return [
            ...this._tailwind.getSpacingClasses(blok),
            ...this._tailwind.getSpacingClasses({
                marginX: blok.align,
                marginXTablet: blok.alignTablet,
                marginXDesktop: blok.alignDesktop,
            }),
            ...this._tailwind.getBoxShadowClasses(blok),
            ...this._tailwind.getBorderRadiusClasses(blok),
        ];
    });
    getInnerClasses = computed<string[]>(() => {
        const blok = this.block();
        return [
            ...this._tailwind.getBackgroundOpacityClasses(blok),
            ...this._tailwind.getBackgroundColorClasses(blok)
        ];
    });
    getDisplayClasses = computed<string[]>(() => {
        return this._tailwind.getDisplayClasses(this.block());
    });
}
