.ekomi {
    &__seal {
        @apply w-28 h-28;
    }

    &__link {
        @apply flex items-center justify-center gap-5;
    }

    &__heading {
        @apply text-2xl font-bold text-primary leading-none;
    }

    &__rating-stars {
        @apply flex items-center gap-2 my-2 flex-col min-[400px]:flex-row;

        &-wrapper--gold {
            @apply absolute top-0 left-0 h-full overflow-hidden w-full;
        }

        &--grey {
            @apply w-full;
        }

        &--gold {
            @apply object-cover h-full object-left;
        }
    }

    &__rating {
        @apply text-primary text-2xl font-bold;
    }

    &__subheading {
        @apply text-center min-[400px]:text-left text-primary text-sm;
    }

}


