@let blok = block();

<div class="s1-grid-column--1 flex flex-col"
     [ngClass]="column1Classes()">
    @for (child of blok.column_one || blok.children1; track child._uid) {
        <s1-cms-component [blok]="child" />
    }
</div>
<div class="s1-grid-column--2 flex flex-col"
     [ngClass]="column2Classes()">
    @for (child of blok.column_two || blok.children2; track child._uid) {
        <s1-cms-component [blok]="child" />
    }
</div>
<div class="as1-grid-column--3 flex flex-col"
     [ngClass]="column3Classes()">
    @for (child of blok.column_three || blok.children3; track child._uid) {
        <s1-cms-component [blok]="child" />
    }
</div>
<div class="s1-grid-column--4 flex flex-col"
     [ngClass]="column4Classes()">
    @for (child of blok.column_four || blok.children4; track child._uid) {
        <s1-cms-component [blok]="child" />
    }
</div>

