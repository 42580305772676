@let blok = block();

@if (blok.children) {
    @if (blok.children.length > 0) {
        @for (child of blok.children; track child._uid) {
            <s1-cms-component [blok]="child"/>
        }
    } @else {
        QuoteBox
    }
}

<div [ngClass]="triangleClasses()"
     class="absolute bottom-0 w-0 h-0 border-l-transparent transform border-t-[2.5rem] border-r-transparent border-l-[3rem] border-r-0"></div>


