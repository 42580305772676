import { CatConfig, DogConfig, HorseConfig } from '../app/cms/components/nested';
import { STORYBLOK_COMPONENTS } from './components';
import { SB_RICH_TEXT_OPTIONS, StoryblokConfig } from '@seven1/angular-storyblok/api';
import { GtmConfig } from '@seven1/angular/gtm';
import { UsercentricsConfig } from '@seven1/angular/usercentrics';
import { KameleoonConfig } from '@seven1/angular/kameleoon';

export type SbIFrameEnv = {
    dog: DogConfig;
    cat: CatConfig;
    horse: HorseConfig;
    general: string
};

export const CMS_CONFIG_STAGE: StoryblokConfig = {
    accessToken: '9RwWhKb3s5D67eniqMOjeQtt',
    storyBasePath: 'petprotect',
    layoutStorySlug: '/layout',
    redirectsStorySlug: '/redirects',
    blogSlug: 'magazin',
    blogArticleContentTypeKey: 'BlogArticlePage',
    version: 'draft',
    components: STORYBLOK_COMPONENTS,
    cache: {
        clear: 'auto',
        type: 'memory',
    },
    richText: SB_RICH_TEXT_OPTIONS,
};

export const CMS_CONFIG_LIVE: StoryblokConfig = {
    accessToken: 'Lht2BVpWBLtcvhIrQucbtgtt',
    storyBasePath: 'petprotect',
    layoutStorySlug: '/layout',
    redirectsStorySlug: '/redirects',
    blogSlug: 'magazin',
    blogArticleContentTypeKey: 'BlogArticlePage',
    version: 'published',
    components: STORYBLOK_COMPONENTS,
    cache: {
        clear: 'auto',
        type: 'memory',
    },
    richText: SB_RICH_TEXT_OPTIONS,
};

export const GTM_CONFIG: GtmConfig = {
    url: 'https://www.googletagmanager.com/',
    id: 'GTM-TV7SLBL',
};

export const UC_CONFIG: UsercentricsConfig = {
    id: 'eGSsZVigU',
    url: 'https://s.p7s1.io/cmp/cmp-petprotect-web.js',
    targetUrl: 'https://versichern.petprotect.de',
    excludedRoutes: ['/impressum', '/datenschutz']
};

export const KAMELEOON_ENV: KameleoonConfig = {
    siteCode: 'vntkkxt0si',
};

export const STORYBLOK_IFRAMES: SbIFrameEnv = {
    dog: {
        // op insurance
        damage: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=op&tarif=unfall&sb=1',
        exclusive_op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=op&tarif=exklusiv&sb=1',
        exclusive_plus_op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=op&tarif=exklusiv%20plus&sb=1',
        // liability insurance
        basis: 'https://versichern.petprotect.de/online-versichern/#/petprotect-thv/Beitrag?tierart=Hund&tarif=basis&sb=1',
        top: 'https://versichern.petprotect.de/online-versichern/#/petprotect-thv/Beitrag?tierart=Hund&tarif=top&sb=1',
        premium: 'https://versichern.petprotect.de/online-versichern/#/petprotect-thv/Beitrag?tierart=Hund&tarif=premium&sb=1',
        // health insurance
        op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=alle&sb=1&vuz=1&tarif=op-exklusiv',
        comfort: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=alle&sb=1&vuz=1&tarif=komfort',
        exclusive: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=alle&sb=1&vuz=1&tarif=exklusiv',
        exclusive_plus: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=alle&sb=1&vuz=1&tarif=exklusiv plus',
    },
    cat: {
        // op insurance
        exclusive_op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Katze&versicherung=op&tarif=exklusiv&sb=1',
        exclusive_plus_op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Katze&versicherung=op&tarif=exklusiv%20plus&sb=1',
        // health insurance
        op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Katze&versicherung=alle&sb=1&vuz=1&tarif=op-exklusiv',
        comfort: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Katze&versicherung=alle&sb=1&vuz=1&tarif=komfort',
        exclusive: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Katze&versicherung=alle&sb=1&vuz=1&tarif=exklusiv',
        exclusive_plus: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Katze&versicherung=alle&sb=1&vuz=1&tarif=exklusiv plus',
    },
    horse: {
        // op insurance
        top_op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Pferd&versicherung=op&tarif=top&sb=1',
        premium_op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Pferd&versicherung=op&tarif=premium&sb=1',
        // liability insurance
        basis: 'https://versichern.petprotect.de/online-versichern/#/petprotect-thv/Beitrag?tierart=pferd&tarif=basis',
        top: 'https://versichern.petprotect.de/online-versichern/#/petprotect-thv/Beitrag?tierart=pferd&tarif=top',
        premium: 'https://versichern.petprotect.de/online-versichern/#/petprotect-thv/Beitrag?tierart=pferd&tarif=premium',
    },
    general: 'https://beratung.petprotect.de/infoanforderung/#/pp/Neu'
};
