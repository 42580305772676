import { inject, Injectable, isDevMode } from '@angular/core';
import { PlatformService } from '@seven1/angular/ssr';
import { UsercentricsService } from '@seven1/angular/usercentrics';
import { ParamMap } from '@angular/router';
import { IKameleoon } from '@seven1/model';

@Injectable({
  providedIn: 'root'
})
export class KameleoonService {

    private _platform = inject(PlatformService);
    private _uc = inject(UsercentricsService);

    /**
     * @param params - a string of query params, seperated by `;` instead of `&`
     * */
    private _extractParams(params: string | undefined): URLSearchParams | null {
        if (!params) {
            return null;
        }
        params = params?.replace(/\s*;\s*/g, '&'); // replace ; with &
        params = params?.replace(/\s*:\s*/g, '='); // replace : with =
        params = params?.replace(/\s*=\s*/g, '='); // remove spaces around =
        try {
            return new URLSearchParams(params);
        } catch (e) {
            if (isDevMode()) {
                console.error(e);
            }
            return null;
        }
    }

    visible(queryParams: ParamMap | Map<string, string> | undefined, kameleoon: IKameleoon): boolean {
        const showParams = this._extractParams(kameleoon.show_when);
        const hideParams = this._extractParams(kameleoon.hide_when);
        const stayHiddenExceptParams = this._extractParams(kameleoon.stay_hidden_except);
        let visible = true;
        for (const hideKey of hideParams?.keys() || []) {
            if (queryParams?.has(hideKey) && queryParams.get(hideKey) === hideParams?.get(hideKey)) {
                visible = false;
            }
        }
        const stayHiddenResults: boolean[] = [];
        for (const stayHiddenExceptKey of stayHiddenExceptParams?.keys() || []) {
            if (queryParams?.has(stayHiddenExceptKey)) {
                if (queryParams?.get(stayHiddenExceptKey) === stayHiddenExceptParams?.get(stayHiddenExceptKey)) {
                    stayHiddenResults.push(true);
                } else {
                    stayHiddenResults.push(false);
                }
            } else {
                stayHiddenResults.push(false);
            }
        }
        if (stayHiddenResults.length) {
            if (stayHiddenResults.includes(false)) {
                visible = false;
            } else { // length > 1 and no false -> so visible = true
                visible = true;
            }
        }
        for (const showKey of showParams?.keys() || []) {
            if (queryParams?.has(showKey) && queryParams.get(showKey) === showParams?.get(showKey)) {
                visible = true;
            }
        }
        return visible;
    }

    init() {
        if (this._platform.isBrowser) {
            this._uc.listen('cmp:consents', () => {
                if (this._uc.checkConsent()) {
                    // @ts-expect-error Kameleoon not defined normally
                    Kameleoon.API.Core.enableLegalConsent();
                } else {
                    if (this._uc.ucGcm) { // only disable if consent was denied
                        // @ts-expect-error Kameleoon not defined normally
                        Kameleoon.API.Core.disableLegalConsent();
                    }
                }
            });
        }
    }
}
