@use "../../../../../../../styles/utils" as utils;

.app-tariff-table {

    &--card {
        @apply p-4 bg-white text-center lg:rounded-3xl lg:shadow-card;
    }

    &--title {
        @apply mx-4 mb-12 text-center break-words;
    }

    &--wrapper {
        @apply flex flex-col gap-x-2 gap-y-4 lg:gap-4;
    }

    &--header {
        @apply hidden lg:grid gap-2 lg:gap-4 items-end;

        &-row-text {
            @apply p-4;
        }
    }
    // header column
    &--column {
        @extend .app-tariff-table--card;
        @apply text-2xl font-bold;
    }

    &--toggle {
        @apply rotate-0 transition-transform duration-200 ease-in-out;

        &.is-expanded {
            @apply rotate-180;
        }
    }

    &--row-group {
        // @apply ;
    }
    &--row {
        @apply grid gap-x-2 lg:gap-x-4 bg-white lg:bg-transparent shadow-card lg:shadow-none rounded-3xl lg:rounded-none overflow-hidden lg:overflow-visible;

        &-children {}
        &-child {}

        &-description {
            @extend .app-tariff-table--card;
            @apply text-left;

            &-child {
                @apply flex items-center w-full;
            }
        }
        &-column-header {}
        &-column {
            &-child {
                @apply border-b-2 border-grey-dark10 lg:border-none;
            }
        }

        &-childless {
            .app-tariff-table--row-description,
            .app-tariff-table--row-column{
                @extend .app-tariff-table--card;
            }
        }
        &-with-children {
            .app-tariff-table--row-description:not(.app-tariff-table--row-description-child),
            .app-tariff-table--row-column:not(.app-tariff-table--row-column-child) {
                @apply p-4 bg-white text-center lg:shadow-card lg:rounded-t-3xl;
            }
            .app-tariff-table--row-description-child,
            .app-tariff-table--row-column-child{
                @apply p-4 bg-white text-center lg:shadow-card;

                &.is-last {
                    @apply lg:rounded-b-3xl;
                }
            }
        }
    }
    &--row-column {
        &-title {
            @apply mb-2 text-dark;
        }
    }

    &--footer {
        @apply lg:grid gap-4;

        &--column {}
    }
}
