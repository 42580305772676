import { ChangeDetectionStrategy, Component, computed, inject, input, SecurityContext, ViewEncapsulation } from '@angular/core';
import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { CachedUrlResolverPipe, SbLinkHelper } from '@seven1/angular-storyblok/link';
import { DesanitizeSlashPipe } from '@seven1/angular/utils';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { ButtonComponent, RouterLinkPipe } from '@seven1/angular-storyblok-components/cta';
import { ITariffCardBlock } from './tariffCard.model';

@Component({
    selector: 'app-tariff-card',
    imports: [ButtonComponent, NgOptimizedImage, CachedUrlResolverPipe, DesanitizeSlashPipe, NgTemplateOutlet, RouterLink, RouterLinkPipe],
    templateUrl: './tariffCard.component.html',
    styleUrl: './tariffCard.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'flex flex-col lg:w-72 overflow-hidden rounded-3xl shadow-card bg-page-background h-full',
        '[class]': 'hostClasses()',
    },
})
export class TariffCardComponent {
    private _tailwind = inject(TailwindService);
    private _sanitizer = inject(DomSanitizer);
    block = input.required<ITariffCardBlock>();
    hostClasses = computed<string[]>(() => {
        return [...this._tailwind.getDisplayClasses(this.block())];
    });
    sanitizedLink = computed(() => {
        return SbLinkHelper.sanitizeLink(this.block().link, this._sanitizer);
    });
    sanitizedResourceLink = computed(() => {
        return this._sanitizer.sanitize(SecurityContext.URL, this.block().asset.filename) || '';
    });
}

