.app-tariff-option-card {
    @apply block lg:flex-1 lg:basis-80 max-w-80 overflow-hidden rounded-3xl shadow-card bg-white;

    &--promoted {
        @apply bg-primary text-white text-sm font-body text-center py-3 px-4;
    }

    &--header {
        @apply text-center py-4 px-4;
    }
    &--title {
        @apply font-bold;
    }
    &--subtitle {}

    &--price-section {
        @apply py-8 px-4 flex flex-col align-middle justify-center text-center bg-primary text-white;
    }
    &--price {
        @apply text-white font-bold;
    }
    &--price-subline {}

    &--secondary-section {
        @apply bg-grey-dark10 text-primary text-center px-6 py-2 italic;
    }

    &--content {
        @apply pt-4 pb-6 px-6;
    }

    &--footer {
        @apply text-center px-6 pb-3;
    }
}
