import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { IParagraphBlock } from './paragraph.model';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';

@Component({
    selector: 'p[s1-paragraph]',
    imports: [],
    templateUrl: './paragraph.component.html',
    styleUrl: './paragraph.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'classes()',
    }
})
export class ParagraphComponent {
    private _tailwind = inject(TailwindService);

    block = input.required<IParagraphBlock>();
    classes = computed<string[]>(() => {
        const block = this.block();
        return [
            'break-words',
            ...this._tailwind.getDisplayClasses(block),
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getSizeClasses(block),
            ...this._tailwind.getTypographyClasses(block),
            ...this._tailwind.getBackgroundColorClasses(block),
            ...this._tailwind.getBorderRadiusClasses(block),
            ...this._getInlineClasses(block),
        ];
    });

    private _getInlineClasses(blok: IParagraphBlock): string[] {
        const classes: string[] = [];
        if (blok.inline) classes.push('max-w-fit');
        if (blok.inlineTablet) classes.push('md:max-w-fit');
        if (blok.inlineDesktop) classes.push('lg:max-w-fit');
        return classes;
    }
}
