import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { TitleStrategy } from '@angular/router';
import { PageTitleStrategy } from './seo-title.strategy';

export const BASE_TITLE = new InjectionToken<string>('base meta title');

export function provideSeo(baseTitle: string): EnvironmentProviders {
    return makeEnvironmentProviders([
        {
            provide: BASE_TITLE,
            useValue: baseTitle
        },
        {
            provide: TitleStrategy,
            useClass: PageTitleStrategy
        }
    ]);
}
