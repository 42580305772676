.s1-table {
    @apply block;

    &--bordered {
        tbody td {
            @apply border-b-2;
        }
    }


    &--appearance {
        &-primary {
            th {
                @apply bg-primary text-white;
            }
            tbody td {
                @apply border-primary;
            }
        }
        &-secondary {
            th {
                @apply bg-secondary text-white;
            }
            tbody td {
                @apply border-secondary;
            }
        }
    }

    &.app-limited-content-width {
        @apply px-5;
    }

    &--table {
        @apply w-full;
    }

    &--thead {
        th:first-child {
            @apply rounded-l-xl;
        }
        th:last-child {
            @apply rounded-r-xl;
        }
    }
    &--header-cell {
    }

    &--tbody {}
    &--row {}
    &--cell {
        @apply px-4 py-2;
    }

    &--tfoot {}
}
