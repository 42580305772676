@let blok = block();
@let inf = loadParams();

@if (blok.link?.url || blok.link?.cached_url) {
    @switch (blok.link!.linktype) {
        @case ('url') {
            <!-- desanitize slash or update sanitizer -->
            <a class="app-link"
               [href]="sanitizedLink() | desanitizeSlash"
               [target]="blok.link!.target"
               [rel]="blok.link!.target === '_blank' ? 'noopener noreferrer' : undefined">
                <ng-container [ngTemplateOutlet]="linkContent"/>
            </a>
        }
        @case ('story') {
            <a class="app-link"
               [routerLink]="blok.link!.cached_url | sbRouterLink | cachedUrlResolver"
               [fragment]="blok.link!.anchor"
               [target]="blok.link!.target">
                <ng-container [ngTemplateOutlet]="linkContent"/>
            </a>
        }
        @case ('asset') {
            <a class="app-link"
               [href]="sanitizedLink() | desanitizeSlash"
               [target]="blok.link!.target">
                <ng-container [ngTemplateOutlet]="linkContent"/>
            </a>
        }
        @case ('email') {
            <a
                class="app-link"
                [href]="'mailto:' + sanitizedLink() | desanitizeSlash">
                <ng-container [ngTemplateOutlet]="linkContent"/>
            </a>
        }
    }
} @else {
    <ng-container [ngTemplateOutlet]="linkContent"/>

}

<ng-template #linkContent>
    @if (inf?.width && inf?.height) {
        <img class="block"
             [ngSrc]="blok.asset.filename"
             sizes="auto"
             [width]="inf?.width"
             [height]="inf?.height"
             [alt]="blok.asset.alt"
             [title]="blok.asset.title"
             [loaderParams]="inf || {}"
             [priority]="blok.priority">
    }
</ng-template>

