.blog-categories-card {
    @apply block shadow-xl p-4 bg-white rounded-2xl;
    &-heading {
        @apply font-bold text-lg lg:text-3xl mb-4 break-words;
    }

    &-link {
        @apply block hover:underline my-1;
    }
}
