@use '../../../../../../styles/utils' as utils;

.app-decorated-section {
    &--wrapper {
        min-height: 30vh;
        position: relative;

        @include utils.media(md) {
            min-height: 45vh;
        }
        @include utils.media(lg) {
            min-height: 60vh;
        }

        @include utils.media(2xl) {
            min-height: 45vh;
        }
    }

    &--children {
        @apply px-5;
    }
    &--children-bottom {
        margin-top: -100px;
        @apply flex flex-col lg:flex-row items-center lg:items-end justify-center gap-8 px-5;

        @include utils.media(lg) {
            margin-top: -300px;
        }
    }
    &--background {
        object-fit: cover;
        z-index: -1;
    }
}
