import {ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation} from '@angular/core';
import {IGroupBlock} from './group.model';
import {TailwindService} from '@seven1/angular-storyblok/tailwind';
import {CmsComponent} from '@seven1/angular-storyblok-cms';

@Component({
    selector: 'div[s1-group]',
    imports: [CmsComponent],
    templateUrl: './group.component.html',
    styleUrl: './group.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 's1-group',
        '[class]': 'classes()',
    }
})
export class GroupComponent {
    private _tailwind = inject(TailwindService);

    block = input.required<IGroupBlock>();

    classes = computed(() => {
        const block = this.block();
        return [
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getSizeClasses(block),
            ...this._tailwind.getBackgroundShadeClasses(block),
            ...this._tailwind.getBorderRadiusClasses(block),
            ...this._tailwind.getTypographyClasses(block),
            ...this._tailwind.getDisplayClasses(block),
            ...this._getFlex(block),
        ];
    });

    private _getFlex(blok: IGroupBlock): string[] {
        const classes: string[] = [];
        if (blok.flexStart) classes.push('flex', 'items-center');
        if (blok.flexCenter) classes.push('flex', 'items-center', 'justify-center');
        if (blok.flexEnd) classes.push('flex', 'items-center', 'justify-end');
        return classes;
    }
}
