import {
    ChangeDetectionStrategy,
    Component, computed,
    effect,
    inject,
    input,
    LOCALE_ID,
    signal,
    ViewEncapsulation
} from '@angular/core';
import { IBlogArticleBlok } from '@seven1/model';
import { StoryblokService } from '@seven1/angular-storyblok/api';
import { IArticleListBlok } from './articles-list.model';
import { ISbStories, ISbStoriesParams, ISbStoryData } from 'storyblok-js-client';
import { BlogArticlePreview } from '../preview';
import { PaginationComponent } from '../../pagination';
import { formatDate } from '@angular/common';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
type pinnedArticlesWithPositionsType =  {uuid:string; position:number}[]
type pinnedArticleOrderType =  {_uid:string;position:number}[]
@Component({
    selector: 's1-articles-list',
    imports: [BlogArticlePreview, PaginationComponent],
    templateUrl: './articles.list.html',
    styleUrl: './articles.list.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 's1-articles-list',
        '[class]': 'classes()'
    }
})
export class BlogArticlesList {
    private _tailwind = inject(TailwindService);
    private _story = inject(StoryblokService);
    private _locale = inject(LOCALE_ID);
    block = input.required<IArticleListBlok>();
    articles = signal<ISbStories<IBlogArticleBlok> | null>(null);
    page = signal(1);

    constructor() {
        effect(() => {
            this.block();
            this.pageChange();
        });
    }

    classes = computed<string[]>(() => {
        const block = this.block();
        return [
            ...this._tailwind.getDisplayClasses(block),
            ...this._tailwind.getSpacingClasses(block),
        ];
    });

    params = computed<ISbStoriesParams>(() => {
        const block = this.block();
        const params: ISbStoriesParams = {
            content_type: this._story.config.blogArticleContentTypeKey,
            sort_by: 'created_at:desc',
            excluding_fields: 'body,blogBody,sidebar',
            filter_query: {},
            per_page:100,
            version: this._story.config.version,
        };
        if (block.categories.length) {
            params.filter_query.categories = {
                any_in_array: block.categories.join(','),
            };
        }
        if (block.tags?.length) {
            params.with_tag = block.tags.join(',');
        }
        if (block.is_startpage) {
            params.is_startpage = block.is_startpage;
        }
        if (block.published_at_gt) {
            params.published_at_gt = formatDate(block.published_at_gt, 'yyyy-MM-dd HH:mm', this._locale);
        }
        if (block.published_at_lt) {
            params.published_at_lt = formatDate(block.published_at_lt, 'yyyy-MM-dd HH:mm', this._locale);
        }
        if (block.first_published_at_gt) {
            params.first_published_at_gt = formatDate(block.first_published_at_gt, 'yyyy-MM-dd HH:mm', this._locale);
        }
        if (block.first_published_at_lt) {
            params.first_published_at_lt = formatDate(block.first_published_at_lt, 'yyyy-MM-dd HH:mm', this._locale);
        }
        return params;
    });


    async pageChange(page: number = this.page()) {
        if (page !== this.page()) this.page.set(page);
        const res = await this._story.getStories<IBlogArticleBlok>(this.params());
        if (res !== null) {
            this.articles.set(this.sortStories(res));
        }
    }

    pairArticlesWithPositions(pinnedArticles: string[], pinnedArticleOrder:pinnedArticleOrderType ) :pinnedArticlesWithPositionsType {
        const pinnedArticleWithPosition = [];
        for (let i = 0; i < pinnedArticles.length; i++) {
            const articleOrder = pinnedArticleOrder[i];
            if (articleOrder && articleOrder.position !== undefined) {
                pinnedArticleWithPosition.push({uuid: pinnedArticles[i], position: articleOrder.position});
            } else {
                pinnedArticleWithPosition.push({uuid: pinnedArticles[i], position: -1});
            }
        }
        return pinnedArticleWithPosition;
    }


    sortStories(stories: ISbStories<IBlogArticleBlok>) {
        const blok = this.block()
        const pinnedArticleWithPosition = this.pairArticlesWithPositions(blok.pinnedArticles ?? [], blok.pinnedArticlesOrder ?? [])
        const pinnedMap = new Map<string, number>();
        const occupiedPositions = new Set<number>();
        const unpinnedArticles: ISbStoryData<IBlogArticleBlok>[] | never = [];
        const unmovableArticles: ISbStoryData<IBlogArticleBlok>[]  = [];
        for (const {uuid, position} of pinnedArticleWithPosition) {
            if (uuid && position !== undefined) {
                const pos = position - 1;
                if (pos >= 0 && !occupiedPositions.has(pos)) {
                    pinnedMap.set(uuid, pos);
                    occupiedPositions.add(pos);
                }
            } else if (uuid) {
                const article = stories.data?.stories.find(story => story.uuid === uuid);
                if (article) {
                    unpinnedArticles.push(article);
                }
            }
        }
        for (const story of stories.data.stories) {
            if (pinnedMap.has(story.uuid)) {
                continue;
            }
            const pinnedData = pinnedArticleWithPosition.find(pinned => pinned.uuid === story.uuid);
            if (pinnedData && pinnedData.position && pinnedData.position < 0) {
                unmovableArticles.push(story);
            } else {
                unpinnedArticles.push(story);
            }
        }
        const sortedStories: ISbStoryData<IBlogArticleBlok>[] = new Array(occupiedPositions.size + unpinnedArticles.length + unmovableArticles.length);
        for (const [uuid, pos] of pinnedMap.entries()) {
            const article = stories.data.stories.find(story => story.uuid === uuid);
            if (article) {
                sortedStories[pos] = article;
            }
        }
        let index = 0;
        for (const story of [...unmovableArticles, ...unpinnedArticles]) {
            while (sortedStories[index] !== undefined) index++;
            sortedStories[index] = story;
        }
        stories.data.stories = sortedStories.filter(item => item !== undefined);
        return stories;
    }
}
