import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { IQuoteBoxBlock } from './quotebox.model';
import { NgClass } from '@angular/common';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { CmsComponent } from '@seven1/angular-storyblok-cms';

@Component({
    selector: 's1-quotebox',
    templateUrl: './quotebox.component.html',
    styleUrl: './quotebox.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, CmsComponent],
    host: {
        '[class]': 'hostClasses()',
        class: 'block relative p-4 rounded-lg',
    },
})
export class QuoteboxComponent {
    private _tailwind = inject(TailwindService);
    block = input.required<IQuoteBoxBlock>();

    hostClasses = computed<string[]>(() => {
        const block= this.block();
        return [
            ...this._tailwind.getDisplayClasses(block),
            ...this._tailwind.getBackgroundColorClasses(block),
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getBackgroundOpacityClasses(block),
            ...this._tailwind.getSizeClasses(block),
        ];
    });

    triangleOpacityClasses(block: IQuoteBoxBlock): string[] {
        const res: string[] = [];
        if (block.backgroundOpacity) {
            res.push(...this._tailwind.getOpacityClasses({ opacity: block.backgroundOpacity }));
        }
        switch (block.backgroundColor) {
            case 'secondary':
                res.push('border-t-secondary');
                break;
            case 'accent-light':
                res.push('border-t-accent-light');
                break;
            case 'accent-dark':
                res.push('border-t-accent-dark');
                break;
            case 'grey-dark':
                res.push('border-t-grey-dark');
                break;
            case 'grey-light':
                res.push('border-t-grey-light');
                break;
            case 'white':
                res.push('border-t-white');
                break;
            case 'primary':
            default:
                res.push('border-t-primary');
                break;
        }
        return res;
    }

    generateTrianglePositionClasses(block: IQuoteBoxBlock): string[] {
        const res: string[] = [];
        const { orientation, maxWidth, maxWidthTablet, maxWidthDesktop } = block;
        const extractedMaxWidthSmaller10 = this.extractNumber(maxWidth) <= 10;
        const extractedMaxWidthTabletSmaller10 = this.extractNumber(maxWidthTablet) <= 10;
        const extractedMaxWidthDesktopSmaller10 = this.extractNumber(maxWidthDesktop) <= 10;
        switch (orientation) {
            case 'left':
                if (extractedMaxWidthSmaller10) {
                    res.push('translate-x-6');
                } else {
                    res.push('translate-x-16')
                }
                if (maxWidthTablet) {
                    if (extractedMaxWidthTabletSmaller10) {
                        res.push('md:translate-x-6');
                    } else {
                        res.push('md:translate-x-16')
                    }
                }
                if (maxWidthDesktop) {
                    if (extractedMaxWidthDesktopSmaller10) {
                        res.push('md:translate-x-6');
                    } else {
                        res.push('md:translate-x-16')
                    }
                }
                break;
            case 'right':
            default:
                if (extractedMaxWidthSmaller10) {
                    res.push('-translate-x-6');
                } else {
                    res.push('-translate-x-16')
                }
                if (maxWidthTablet) {
                    if (extractedMaxWidthTabletSmaller10) {
                        res.push('md:-translate-x-6');
                    } else {
                        res.push('md:-translate-x-16')
                    }
                }
                if (maxWidthDesktop) {
                    if (extractedMaxWidthDesktopSmaller10) {
                        res.push('md:-translate-x-6');
                    } else {
                        res.push('md:-translate-x-16')
                    }
                }
                break;
        }
        return res;
    }

    trianglePositionClasses(block: IQuoteBoxBlock): string[] {
        const res: string[] = ['translate-y-10'];
        switch (block.orientation) {
            case 'left':
                res.push('left-0', ...this.generateTrianglePositionClasses(block));
                break;
            case 'right':
            default:
                res.push('right-0', 'scale-x-[-1]', ...this.generateTrianglePositionClasses(block));
                break;
        }
        return res;
    }

    triangleClasses = computed(() => {
        const blok = this.block();
        return [
            ...this.triangleOpacityClasses(blok),
            ...this.trianglePositionClasses(blok),
        ];
    });

    extractNumber = (text?: string): number => {
        const match = text?.match(/\d+(\.\d+)?/);
        return match ? Number(match[0]) : 11;
    };
}
