@let blok = block();

@if (blok.promoted) {
    <p class="bg-primary text-white text-sm font-body text-center px-3 py-1 lg:px-3 lg:py-3">{{ blok.promotedTitle }}</p>
}
<div class="flex flex-row lg:flex-col lg:h-auto lg:min-h-44">
    @if (blok.asset.filename) {
        <img
            class="w-1/4 max-w-28 lg:max-w-full lg:w-full lg:h-56 object-cover"
            sizes="auto"
            height="288"
            width="288"
            alt="{{blok.asset.alt}}"
            [ngSrc]="sanitizedResourceLink()"
            [title]="blok.asset.title"
            [priority]="blok.priority"
        />
    }
    <div class="flex flex-row lg:flex-col justify-between items-center gap-3 px-5 py-5 lg:px-10 w-full lg:w-full">
        <div class="flex flex-col">
            @if (blok.descriptionText) {
                <h2 class="text-left lg:text-center w-full break-words hyphens-auto text-sm lg:text-lg font-body">{{ blok.descriptionText }}</h2>
            }
            @if (blok.subDescriptionText) {
                <p class="text-left lg:text-center w-full break-words hyphens-auto text-xs lg:text-base font-body">{{ blok.subDescriptionText }}</p>
            }
        </div>
        <ng-container [ngTemplateOutlet]="cardLink"/>
    </div>
</div>


<ng-template #cardLink>
    @if (blok.buttonText) {
        <s1-button [block]="{text:blok.buttonText, color:blok.buttonColor, size:blok.buttonSize,link:blok.link}"
                    class="!hidden lg:!block"/>
    }
    @if (blok.link) {
        @switch (blok.link!.linktype) {
            @case ('story') {
                <a class="lg:hidden"
                   [routerLink]="blok.link.cached_url | sbRouterLink | cachedUrlResolver"
                   [fragment]="blok.link.anchor"
                   [target]="blok.link.target">
                    <ng-container [ngTemplateOutlet]="icon"/>
                </a>
            }
            @case ('url') {
                <a class="lg:hidden"
                   [href]="sanitizedLink() | desanitizeSlash"
                   [target]="blok.link.target"
                   [rel]="blok.link.target === '_blank' ? 'noopener noreferrer' : undefined">
                    <ng-container [ngTemplateOutlet]="icon"/>
                </a>
            }
            @case ('email') {
                <a class="lg:hidden" [href]="'mailto:' + sanitizedLink() | desanitizeSlash"
                >
                    <ng-container [ngTemplateOutlet]="icon"/>
                </a>
            }
            @case ('asset') {
                <a class="lg:hidden"
                   [href]="sanitizedLink() | desanitizeSlash"
                   [target]="blok.link.target"
                >
                    <ng-container [ngTemplateOutlet]="icon"/>
                </a>
            }
        }
    }

</ng-template>


<ng-template #icon>
    <img class="inline-block bg-accent-dark rounded-xl min-w-10"
         ngSrc="/icons/arrow-right.svg"
         width="46"
         height="44"
         alt="Call"
    />
</ng-template>
