import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { IBadgeBlock } from './badge.model';

@Component({
    selector: 's1-badge',
    imports: [],
    templateUrl: './badge.component.html',
    styleUrl: './badge.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'flex flex-col justify-center items-center w-40 h-40 bg-primary rounded-full rotate-12 p-5',
        '[class]': 'classes()',
    }
})
export class BadgeComponent {
    block = input.required<IBadgeBlock>();
    private _tailwind = inject(TailwindService);
    classes = computed(() => {
        const block = this.block();
        return [...this._tailwind.getSpacingClasses(block)];
    });
}
