@let blok = block();

<div class="flex items-center gap-3">
    @if (blok.icon) {
        @if (blok.icon.length > 0) {
            @for (child of blok.icon; track child._uid) {
                <s1-cms-component [blok]="child"/>
            }
        } @else {
            Icon
        }
    }
    @if (blok.heading) {
        @if (blok.heading.length > 0) {
            @for (child of blok.heading; track child._uid) {
                <s1-cms-component [blok]="child"/>
            }
        } @else {
            Heading
        }
    }
</div>

@if (blok.content) {
    @if (blok.content.length > 0) {
        @for (child of blok.content; track child._uid) {
            <s1-cms-component [blok]="child"/>
        }
    } @else {
        Content
    }
}
