.s1-pagination {
    @apply flex justify-center items-center gap-4;

    &--nav {
        @apply flex justify-center items-center p-8 rounded-full text-center font-bold;
    }
    &--index {
        @apply flex justify-center items-center p-8 rounded-full text-center font-bold;

        &.is-active {
        }
    }
}
