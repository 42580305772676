import { ChangeDetectionStrategy, Component, computed, inject, input, SecurityContext, ViewEncapsulation } from '@angular/core';
import { IRichTextBlock } from './rich-text.model';
import { StoryblokService } from '@seven1/angular-storyblok/api';
import { DomSanitizer } from '@angular/platform-browser';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';

@Component({
    selector: 'div[s1-rich-text]',
    imports: [],
    templateUrl: './rich-text.component.html',
    styleUrl: './rich-text.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 's1-rich-text',
        '[class]': 'classes()',
        '[innerHtml]': 'html()',
    }
})
export class RichTextComponent {
    private _tailwind = inject(TailwindService);
    private _storyblok = inject(StoryblokService);
    private _sanitizer = inject(DomSanitizer);

    block = input.required<IRichTextBlock>();

    html = computed(() => {
        const text = this.block().text;
        const renderedText = this._storyblok.richTextResolver.render(text);
        return renderedText
            ? this._sanitizer.sanitize(
                SecurityContext.HTML,
                Array.isArray(renderedText)
                    ? renderedText.join('')
                    : renderedText
            )
            : undefined;
    });
    classes = computed<string[]>(() => {
        const block = this.block();
        return [
            ...this._tailwind.getDisplayClasses(block),
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getSizeClasses(block),
            ...this._tailwind.getTypographyClasses(block),
        ];
    });
}
