import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { IOneDirectionBlock } from './one-direction.model';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { CmsComponent } from '@seven1/angular-storyblok-cms';

@Component({
    selector: 'div[s1-one-direction]',
    imports: [CmsComponent],
    templateUrl: './one-direction.component.html',
    styleUrl: './one-direction.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'flex flex-wrap',
        '[class]': 'this.classes()',
    }
})
export class OneDirectionComponent {
    private _tailwind = inject(TailwindService);

    block = input.required<IOneDirectionBlock>();
    classes = computed(() => {
        const block = this.block();
        return [
            ...this._tailwind.getFlexboxClasses(block),
            ...this._tailwind.getSpacingClasses(block)
        ];
    });
}
