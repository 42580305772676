.s1-categories-list {
    @apply block max-w-content-width mx-auto;

    &--list {
        @apply grid grid-cols-12 gap-6 mx-6 2xl:ml-0 ;
    }

    &--item {
        @apply block col-span-12 md:col-span-6;
    }
}
