import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { CmsComponent } from '@seven1/angular-storyblok-cms';
import { IBlogGeneralUseCardBlok } from './blog-general-use-card.model';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';

@Component({
    selector: 's1-blog-general-use-card',
    imports: [
        CmsComponent
    ],
    templateUrl: './blog-general-use-card.component.html',
    styleUrl: './blog-general-use-card.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'blog-general-use-card',
        '[class]': 'classes()'
    }
})
export class BlogGeneralUseCardComponent {
    private _tailwind = inject(TailwindService);
    block = input.required<IBlogGeneralUseCardBlok>();

    classes = computed<string[]>(() => {
        const block = this.block();
        return [
            ...this._tailwind.getDisplayClasses(block),
            ...this._tailwind.getSpacingClasses(block),
        ];
    });
}
