import { IGridBlock } from './grid.model';
import { ColSpanOption } from '@seven1/model';

export function gridInputTransform<T extends IGridBlock>(value: T & {
    colSpan?: ColSpanOption[] | string;
    colSpanTablet?: ColSpanOption[] | string;
    colSpanDesktop?: ColSpanOption[] | string;
}): T {
    if (typeof value?.colSpan === 'string' && value?.colSpan.startsWith('[')) {
        value.colSpan = JSON.parse(value.colSpan) as ColSpanOption[];
    }
    if (typeof value?.colSpanTablet === 'string' && value?.colSpanTablet.startsWith('[')) {
        value.colSpanTablet = JSON.parse(value.colSpanTablet) as ColSpanOption[];
    }
    if (typeof value?.colSpanDesktop === 'string' && value?.colSpanDesktop.startsWith('[')) {
        value.colSpanDesktop = JSON.parse(value.colSpanDesktop) as ColSpanOption[];
    }

    return value || {} as T;
}
